/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, withRouter } from "react-router-dom";

import moment from "moment";
import "moment/locale/fi"; // without this line it didn't work

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
// Font Awesome 5 icons
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
// Nucleo icons
import "assets/vendor/nucleo/css/nucleo.css"; // icons
import "react-notification-alert/dist/animate.css";

// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

import "./services/i18n";

import { AppProvider } from "contexts/AppContext";
import { AuthProvider } from "contexts/AuthContext";
import { NotificationProvider } from "stories/components/Notification";
import history from "services/history";
import { StoreProvider } from "easy-peasy";
import { store } from "store/index";

import App from "./app";

moment().locale("fi");
require("date-utils");

// This component removes the fbclid parameter from the URL - Facebook adds this to links
const RemoveFbclid = withRouter(({ location, history }) => {
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("fbclid")) {
      params.delete("fbclid");
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
    }
  }, [location, history]);

  return null;
});

ReactDOM.render(
  <BrowserRouter>
    <RemoveFbclid />
    <StoreProvider store={store}>
      <AppProvider>
        <AuthProvider history={history}>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </AuthProvider>
      </AppProvider>
    </StoreProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
